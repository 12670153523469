<template>
    <div>
        <card-header title="Customers" icon="fa-users"/>
        <card-toolbar>
            <button @click="$openCard('new-customer', {}, card)"><i class="fas fa-user-plus mr-2"/>New Customer</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <search-list-item @action="searchUsers"/>
                <subheader-list-item title="Recently Accessed" icon="fas fa-history"/>
                <user-list-item
                    :card="card"
                    :user="u"
                    v-for="u in recent"
                    :active="child && child.definition.component === 'customer' && child.props.userId === u.id.toString()"
                    @click="$openCard('customer', {userId: u.id}, card);"
                />
                <subheader-list-item title="New Customers" icon="fas fa-plus-square"/>
                <user-list-item
                    :card="card"
                    :user="u"
                    v-for="u in newCustomers"
                    :active="child && child.definition.component === 'customer' && child.props.userId === u.id.toString()"
                    @click="$openCard('customer', {userId: u.id}, card);"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CustomerListItem from "../../components/CustomerListItem";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import CardBody from "@/TIER/components/CardBody";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import {client as http} from "@/http_client";
    import UserListItem from "@/components/CustomerListItem";
    import async from "async";

    export default {
        name: "Users",
        props: ['card', 'child'],
        components: {UserListItem, SubheaderListItem, CardBody, SearchListItem, CardToolbar, CustomerListItem, CardList, CardHeader},
        data: function() {
            return {
                recent: [],
                newCustomers: []
            };
        },
        methods: {
            loadCustomers(force) {
                this.$emit('loading', true);

                async.parallel([
                    (cb) => {
                        http.get('/api/users/recent', {force}).then(response => {
                            for (let i = 0; i < response.data.length; i++) {
                                response.data[i].active = false;
                            }
                            this.recent = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/users/created', {force}).then(response => {
                            for (let i = 0; i < response.data.length; i++) {
                                response.data[i].active = false;
                            }
                            this.newCustomers = response.data;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            searchUsers: function(q) {
                this.$openCard('search-customers', {query: q}, this.card);
            },
            reload: function() {
                this.loadCustomers(true);
            }
        },
        mounted() {
            this.loadCustomers();
        }
    };
</script>

<style lang="scss" scoped>

</style>
